export type accessLevelTypes = 'full' | 'read' | 'get_only' | null;

export interface PermissionModel {
    pathName: string,
    driver: accessLevelTypes,
    operator: accessLevelTypes,
    maintainer: accessLevelTypes,
    admin: accessLevelTypes,
    owner: accessLevelTypes,
    superuser: 'full'
}

const PermissionRules: PermissionModel[] = [
    {
        pathName: "users",
        driver: null,
        operator: null,
        maintainer: null,
        admin: "read",
        owner: "full",
        superuser: "full"
    },
    {
        pathName: "clients",
        driver: null,
        operator: null,
        maintainer: null,
        admin: null,
        owner: null,
        superuser: "full"
    },
    {
        pathName: "permissions",
        driver: null,
        operator: null,
        maintainer: null,
        admin: null,
        owner: null,
        superuser: "full"
    },
    {
        pathName: "paymentMethods",
        driver: null,
        operator: "read",
        maintainer: "read",
        admin: "full",
        owner: "full",
        superuser: "full"
    },
    {
        pathName: "bottlesList",
        driver: "read",
        operator: "read",
        maintainer: "read",
        admin: "full",
        owner: "full",
        superuser: "full"
    },
    {
        pathName: "chamber",
        driver: "read",
        operator: "read",
        maintainer: "full",
        admin: "full",
        owner: "full",
        superuser: "full"
    },
    {
        pathName: "chamber_history",
        driver: "read",
        operator: "read",
        maintainer: "read",
        admin: "read",
        owner: "read",
        superuser: "full"
    },
    {
        pathName: "loadsList",
        driver: "full",
        operator: "full",
        maintainer: "full",
        admin: "full",
        owner: "full",
        superuser: "full"
    },
    {
        pathName: "penalty",
        driver: null,
        operator: "read",
        maintainer: "read",
        admin: "full",
        owner: "full",
        superuser: "full"
    },
    {
        pathName: "priceList",
        driver: null,
        operator: "read",
        maintainer: "read",
        admin: "full",
        owner: "full",
        superuser: "full"
    },
    {
        pathName: "transactions",
        driver: null,
        operator: "full",
        maintainer: "full",
        admin: "full",
        owner: "full",
        superuser: "full"
    },
    {
        pathName: "report",
        driver: null,
        operator: null,
        maintainer: null,
        admin: "read",
        owner: "read",
        superuser: "full"
    },
    {
        pathName: "notifications",
        driver: null,
        operator: "read",
        maintainer: "read",
        admin: "read",
        owner: "read",
        superuser: "full"
    },
    {
        pathName: "machineGroups",
        driver: 'read',
        operator: 'read',
        maintainer: 'read',
        admin: 'read',
        owner: 'read',
        superuser: "full"
    },
    {
        pathName: "machines",
        driver: "read",
        operator: "read",
        maintainer: "read",
        admin: "full",
        owner: "full",
        superuser: "full"
    },
    {
        pathName: "machine_command",
        driver: null,
        operator: "full",
        maintainer: "full",
        admin: "full",
        owner: "full",
        superuser: "full"
    },
    {
        pathName: "machineUsers",
        driver: null,
        operator: null,
        maintainer: "read",
        admin: "full",
        owner: "full",
        superuser: "full"
    },
    {
        pathName: "groupBottles",
        driver: "read",
        operator: "read",
        maintainer: "read",
        admin: "full",
        owner: "full",
        superuser: "full"
    },
    {
        pathName: "credit",
        driver: null,
        operator: "read",
        maintainer: "full",
        admin: "full",
        owner: "full",
        superuser: "full"
    },
    {
        pathName: "collect_images",
        driver: null,
        operator: null,
        maintainer: "full",
        admin: "full",
        owner: "full",
        superuser: "full"
    },
    {
        pathName: "layout",
        driver: null,
        operator: null,
        maintainer: null,
        admin: 'get_only',
        owner: 'get_only',
        superuser: "full"
    }
];

export default PermissionRules